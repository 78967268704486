<template>
  <video ref="video" class="video" :src="src" controls autoplay />
</template>

<script>
export default {
  data() {
    return {
      src: ''
    }
  },
  created() {
    this.src = this.$route.query.src
    document.title = this.$route.query.title
    // this.$refs.video.play()
  }
}
</script>

<style>
.video {
  width: 100vw;
  height: 100vh;
  display: block;
  background: #000;
}
</style>
